@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Oxanium:wght@200..800&display=swap');

body {
  padding: 0;
  margin: 0;
  font-family: "Manrope", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Manrope", sans-serif;
}

/* @font-face {
  font-family: "Mona Sans";
  src: url("./fonts/Mona-Sans.woff2") format("woff2 supports variations"),
    url("./fonts/Mona-Sans.woff2") format("woff2-variations");
  font-weight: 200 900;
  font-stretch: 75% 125%;
} */

/* html {
  font-family: "Manrope", sans-serif;
} */

a {
  border-bottom: none;
}
